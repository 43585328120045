.software_container {
  text-align: center;
  font-family: "Arial", sans-serif;
  padding: 96px;
  background-color: #ffffff;
}

.software_header {
  font-size: 32px;
  color: #000000;
  margin-bottom: 30px;
  font-weight: bold;
}

.software_gridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 1000px;
}

.software_fullWidthCard {
  grid-column: span 2;
}

.software_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f1f5f8;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.software_cardTitle {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 10px;
}

.software_cardImage {
  width: 100%;
  max-width: 300px;
  height: auto;
  margin-bottom: 15px;
}

.software_cardDescription {
  font-size: 14px;
  color: #000000;
  margin-top: 10px;
}

@media (max-width: 992px) {
  .software_gridContainer {
    grid-template-columns: 1fr;
  }

  .software_fullWidthCard {
    grid-column: span 1;
  }
  .software_container {
    padding: 46px;
  }
}

@media (max-width: 576px) {
  .software_gridContainer {
    grid-template-columns: 1fr;
  }

  .software_fullWidthCard {
    grid-column: span 1;
  }
  .software_container {
    padding: 24px;
  }
}
