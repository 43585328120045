.team_container {
  text-align: center;
  padding: 96px;
  background-color: #f1f5f8;
}

.team_title {
  font-family: Lato;
  font-size: 48px;
  font-weight: 500;
  line-height: 57.6px;
  letter-spacing: 0.2px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.team_cardContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 40px 0;
}

.team_cardColumn {
  width: 296px;
  margin: 10px;
  display: flex;
  justify-content: center;
}

.team_card {
  border-radius: 10px;

  width: 100%;
  width: Fixed (296px) px;
  height: Fixed (296px) px;
  padding: 48px 0px 0px 0px;
  gap: 10px;

  opacity: 1px;

  background: #ffffff;

  box-shadow: 4px 4.97px 14.92px 3.5px #00000040;
}

.team_cardTitle {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: #000000;
  margin: 10px 0;
}

.team_cardRole {
  color: #5e5e5e;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
@media (max-width: 992px) {
  .team_container {
    padding: 46px;
  }
}

@media (max-width: 576px) {
  .team_container {
    padding: 24px;
  }
}
