*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.background_section {
  background-color: #f1f5f8;
  background-image: url("./../../assets/rush-technology.png");
  background-size: cover;
  background-position: center;
  padding-top: 75px;
  color: white;
  max-width: 100%;
  overflow-x: hidden;
}

.contactBtn {
  background: white !important;
  color: #0f356e !important;
  border-radius: 8px;
  padding: 16px 20px;
  font-weight: 700;
  font-size: 16px;
  font-family: Lato, sans-serif;
}

.content {
  font-size: 18px;
  font-weight: 400;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.card_title {
  font-size: 24px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.content_container {
  max-width: 100%;
  overflow-x: hidden;
  padding: 96px;
}

@media (max-width: 992px) {
  .content_container {
    padding: 46px;
  }
}

@media (max-width: 576px) {
  .content_container {
    padding: 24px;
  }
}

.card_body_custom {
  padding-top: 50px !important;
  padding-bottom: 24px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.card {
  background-color: #f1f5f8 !important;
  max-width: 100%;
  margin: 0 auto;
  padding: 16px;
}

.card_icon {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  width: 70px;
  height: 70px;
  object-fit: contain;
  z-index: 1;
  margin-left: 5px;
}

.card_icon img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}

img {
  max-width: 100%;
  height: auto;
}
