@media (min-width: 992px) {
  .custom_navbar {
    padding: 10px 96px;
  }

  .custom_nav_link {
    padding: 16px 20px;
    transition: background-color 0.3s ease;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    font-size: 16px;
  }

  @media (hover: hover) and (pointer: fine) {
    .custom_nav_link:hover,
    .custom_nav_link.active {
      background: #0f356e !important;
      color: white !important;
      border-radius: 5px;
    }
  }
}
.navbar_toggler {
  border: none !important;
}
