.contactContainer {
  font-family: Arial, sans-serif;
  background-color: #d1e2ef;
  padding: 96px;
  text-align: center;
}

.contactTitle {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #000;
}

.contactContent {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  flex-wrap: wrap;
}

.contactInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: auto;
}

.infoBox {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 400px;
}

.icon {
  background-color: #0f356e;
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 0 auto 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconImage {
  font-size: 30px;
}

.infoTitle {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
}

.infoText {
  font-size: 1rem;
  color: #666;
}

.contactForm {
  background: #d1e2ef;
  border-radius: 10px;
  padding: 30px;
  width: 450px;
  margin-top: -30px;
}

.formInputBlock,
.formTextareaBlock,
button.formSubmit {
  font-family: Arial;
  width: 500px;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #f5f5f5;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  outline: none;
  color: #1d2130;
  box-sizing: border-box;
}

.formTextareaBlock {
  height: 120px;
  resize: none;
}

.formInputBlock:focus,
.formTextareaBlock:focus {
  background-color: #e8e8e8;
  box-shadow: 0 0 4px #003a78;
}

button.formSubmit {
  background-color: #0f356e;
  color: #fff;
  font-size: 1rem;
  padding: 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 500px;
  margin-top: 10px;
}

.formSubmit:hover {
  background-color: #0f356e;
}

@media screen and (max-width: 996px) {
  .contactContent {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .contactContainer {
    padding: 46px;
  }

  .contactForm {
    order: 1;
    width: 90%;
    max-width: 500px;
    padding: 20px;
    background: #d1e2ef;
    border-radius: 10px;
    box-sizing: border-box;
  }

  .formInputBlock,
  .formTextareaBlock,
  button.formSubmit {
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    background-color: #f5f5f5;
    border: none;
    border-radius: 10px;
    font-size: 1rem;
    box-sizing: border-box;
  }

  button.formSubmit {
    background-color: #0f356e;
    color: #fff;
    font-size: 1rem;
    padding: 15px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
  }

  button.formSubmit:hover {
    background-color: #003a78;
  }

  .contactInfo {
    order: 2;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 90%;
  }

  .infoBox {
    width: 100%;
    max-width: 500px;
    padding: 20px;
    background: #fff;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 576px) {
  .contactContainer {
    padding: 24px;
  }

  .contactContent {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .contactForm {
    order: 1;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background: #d1e2ef;
    border-radius: 10px;
    box-sizing: border-box;
  }

  .formInputBlock,
  .formTextareaBlock,
  button.formSubmit {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    background-color: #f5f5f5;
    border: none;
    border-radius: 10px;
    font-size: 1rem;
    box-sizing: border-box;
  }

  button.formSubmit {
    background-color: #0f356e;
    color: #fff;
    font-size: 1rem;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 10px;
    padding: 12px;
    width: 100%;
  }

  button.formSubmit:hover {
    background-color: #003a78;
  }

  .contactInfo {
    order: 2;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 100%;
  }

  .infoBox {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background: #fff;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }
}
