.spz_container {
  text-align: center;
  padding: 0px 96px 96px 96px;
  position: relative;
  box-sizing: border-box;
}

.spz_heading {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 40px;
}

.specializations {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow-x: auto; 
  padding: 0 10px; 
  border-top: 1.5px dashed #0F356E; 
  width: calc(100% - 20px); 
  box-sizing: border-box; 
}

.specializations::-webkit-scrollbar {
  display: none; 
}

.specializations {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 10px; 
  position: relative;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-bottom: 10px;
  position: relative;
}

.item:nth-child(1) .circle {
  background-color: #89BBE4;
}

.item:nth-child(2) .circle {
  background-color: #3ECDF9;
}

.item:nth-child(3) .circle {
  background-color: #356574;
}

.item:nth-child(4) .circle {
  background-color: #3D9EBC;
}

.item:nth-child(5) .circle {
  background-color: #2E8CD8;
}

.item:nth-child(6) .circle {
  background-color: #38879F;
}

.spz_text {
  font-size: 16px;
  color: #000;
  margin-top: 10px;
  font-weight: bold;
  word-wrap: break-word; 
  text-align: center; 
  max-width: 100%; 
  white-space: normal; 
}

@media (max-width: 992px) {
  .specializations {
    justify-content: flex-start;
    padding: 0 10px;
  }
 
  .item {
    flex: 0 0 auto;
    margin: 0 10px;
  }
  .spz_container {
    padding: 46px;
  }
}
 
@media (max-width: 576px) {
  .specializations {
    justify-content: flex-start;
    padding: 0 10px;
  }
 
  .item {
    flex: 0 0 auto;
    margin: 0 5px;
  }
 
  .spz_heading {
    font-size: 20px;
  }
 
  .spz_text {
    font-size: 14px;
  }
  .spz_container {
    padding: 24px;
  }
}