.footer {
  background-color: #0f356e;
  color: white;
  padding-top: 40px;
  padding-bottom: 20px;
}

text {
  font-family: "Exo 2";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-decoration: none;
  color: inherit;
  margin-bottom: 24px;
}
p a.text {
  color: inherit;
  text-decoration: none;
}

.Caption {
  font-family: "Exo 2", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.075em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 24px;
}

.bottomSection {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  font-family: "Exo 2", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  margin-top: 24px;
  padding-top: 10px;
}

.pointer {
  cursor: pointer;
}
@media (max-width: 576px) {
  .footer {
    padding: 24px;
  }
}
