.project_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 96px;
  flex-direction: column;
  overflow-x: hidden;
}

.project_title {
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 40px;
}

.main_content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 100%;
  text-align: left;
}

.projectContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
}

.leftContent {
  flex-direction: row;
}

.rightContent {
  flex-direction: row-reverse;
}

.project_image {
  width: 100%;
  max-width: 400px;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
}

.project_text {
  max-width: 600px;
}

.projectTitle {
  font-size: 36px;
  color: #000000;
  margin-bottom: 0px;
  gap: 24px;
}

.project_description {
  font-size: 16px;
  color: #000000;
  padding: 10px 10px 10px 0px;
  text-align: left;
}

@media (min-width: 992px) {
  .main_content {
    flex-direction: column;
  }
  .projectContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    width: 100%;
  }
  .leftContent {
    flex-direction: row;
  }
  .rightContent {
    flex-direction: row-reverse;
  }
}

@media (max-width: 992px) and (min-width: 576px) {
  .main_content {
    flex-direction: column;
  }
  .projectContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .leftContent,
  .rightContent {
    flex-direction: column;
  }
  .project_image {
    max-width: 100%;
  }
  .project_container {
    padding: 46px;
  }
}

@media (max-width: 576px) {
  .main_content {
    flex-direction: column;
  }
  .project_container {
    padding: 24px;
  }
  .projectContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .leftContent,
  .rightContent {
    flex-direction: column;
  }
  .project_image {
    max-width: 100%;
  }
  .project_text {
    padding: 10px 20px;
  }
}
