.aboutUs_container {
  background-image: url("../../assets/about-us.png");
  background-size: cover;
  background-position: center center;
  padding: 90px;
  text-align: center;
}

.aboutUs_title {
  font-size: 40px;
  margin-bottom: 30px;
  font-weight: bold;
  color: #ffffff;
}

.aboutUs_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
  background: transparent;
}

.aboutUs_imageSection {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.aboutUs_image {
  width: 100%;
  max-width: 90%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.aboutUs_textSection {
  font-family: Lato, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  color: #ffffff;
  background: transparent;
}

.aboutUs_textSection p {
  font-size: 17px;
  line-height: 1.6;
  margin-bottom: 20px;
}

@media (max-width: 996px) {
  .aboutUs_grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .aboutUs_imageSection {
    margin-top: 20px;
  }

  .aboutUs_textSection {
    text-align: left;
    padding: 20px;
  }

  .aboutUs_title {
    font-size: 35px;
  }

  .aboutUs_textSection p {
    font-size: 16px;
  }
  .aboutUs_container {
    padding: 46px;
  }
}

@media (max-width: 576px) {
  .aboutUs_grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .aboutUs_imageSection {
    margin-top: 15px;
  }

  .aboutUs_textSection {
    padding: 15px;
    text-align: left;
  }

  .aboutUs_title {
    font-size: 30px;
  }
  .aboutUs_container {
    padding: 24px;
  }

  .aboutUs_textSection p {
    font-size: 14px;
  }
}
